@import "./theming";

html,
body {
    height: 100%;
    width: 100%;
    padding: 0;
    margin: 0;

    font-size: 16px;
    font-family: "Roboto", sans-serif;
    background-color: #fafafa;
    color: rgba(0, 0, 0, 0.87);
}

a {
    color: var(--color-primary);
}

.mat-icon--filled,
.mat-icon--outlined {
    display: inline-block;
    width: 1em;
    height: 1em;
    font-size: 24px;
    font-family: "Material Symbols Outlined";
    vertical-align: middle;
}
.mat-icon--filled {
    font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 48;
}
.mat-icon--outlined {
    font-variation-settings: "FILL" 0, "wght" 400, "GRAD" 0, "opsz" 48;
}


.mat-card-header-text {
    margin: 0 !important;
    color: #455a64;
  }
  

// This is mostly used for contentful-managed content. We don't control the
// generated HTML, so we can't assign classes to the elements themselves.
.text-content {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    li,
    p {
        max-width: 80ch;
    }

    h1, h4 {
        color: var(--color-primary);
    }

    h1 {
        line-height: 1.05;
    }
    h4 {
        margin: 1em 0 0.5em 0;
    }

    p {
        line-height: 1.4;
        margin: 0 0 1em 0;
    }

    hr {
        width: 50%;
        margin: 32px auto;
        border-top: 0;
        border-color: var(--color-primary);
        opacity: 0.8;
    }

    img {
        max-width: 100%;
    }
}

.shell {
    display: grid;
    overflow: hidden;
    grid-template-rows: auto 1fr;
    grid-template-columns: auto 1fr;
    grid-template-areas:
        "header header"
        "sidebar  main";
}

.shell-header {
    background: var(--color-top-bar);
    height: 64px;
    padding: 0 16px;
    grid-area: header;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;
}

.shell-main-container {
    position: relative;
    box-sizing: border-box;
    min-height: 100%;
    padding: 1rem;
    grid-area: main;
    align-self: stretch;
    overflow: auto;
}

@import "./sidebar";
@import "./topBar";
