$expanded-selector: " \
  .shell-sidebar--expanded, \
  .shell-sidebar--hover \
";
$collapsed-selector: ".shell-sidebar--collapsed:not(.shell-sidebar--hover)";

$collapse-time: 0.2s;

.shell-sidebar {
    position: relative;
    display: grid;
    grid-template-rows: 1fr auto;

    grid-area: sidebar;
    background: white;
    align-self: stretch;
    min-height: 10px;
    z-index: 100;

    transition: width $collapse-time ease;
}

.shell-sidebar-collapse-control {
    position: absolute;
    top: 64px;
    left: 100%;
    width: 1.25em;
    height: 1.25em;
    padding: 0;
    border-radius: 100%;
    border: 1px solid var(--color-primary);

    opacity: 0;
    color: var(--color-primary);
    // font-variation-settings: "wght" 600;
    transform: translateX(-50%);
    cursor: pointer;
    background-color: white;
    transition: opacity $collapse-time;

    .shell-sidebar:hover & {
        opacity: 1;
    }

    .shell-sidebar--collapsed & {
        transform: translateX(-50%) scaleX(-100%);
        opacity: 1;
    }
}

#{$collapsed-selector} {
    width: 72px;
}
#{$expanded-selector} {
    width: 320px;
}

#{$collapsed-selector} .shell-sidebar-nav {
    width: 72px;
    overflow-x: hidden;
}

.shell-sidebar-footer {
    width: 100%;
    text-align: center;
    opacity: 1;
    transform: translateY(0);

    transition: opacity 0.2s $collapse-time ease,
        transform 0.2s $collapse-time ease-out;

    #{$collapsed-selector} & {
        width: 72px;
        opacity: 0;
        transform: translateY(8px);
        transition: none;
    }
}

.shell-sidebar-nav {
    max-height: 100%;
    overflow-y: auto;
}
.shell-sidebar-nav-entry {
    list-style: none;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
}
.shell-sidebar-nav-group {
    overflow: hidden;

    & > * > .shell-sidebar-nav-name:after {
        content: "chevron_right";
        margin-left: auto;
        margin-right: 16px;

        font-family: "Material Symbols Outlined";
        font-size: 24px;
        line-height: 18px;
        color: var(--color-primary);
        transform: rotateZ(.25turn);
        transition: transform $collapse-time;
    }

    &.shell-sidebar-nav-group--expanded > * > .shell-sidebar-nav-name:after {

        transform: rotateZ(.75turn);
    }
}
.shell-sidebar-nav-line {
    display: flex;

    padding: 8px 0;
    padding-left: max(10px, calc(25px * var(--nav-depth, 0)));

    text-decoration: none;
    text-decoration: none;
    color: inherit;
    transition: background-color $collapse-time, padding $collapse-time;


    &:hover {
        background-color: rgba(238, 238, 238);
    }

    #{$collapsed-selector} & {
        padding-left: max(10px, calc(10px + 8px * var(--nav-depth, 0)));
    }
}
.shell-sidebar-nav-link--active {
    background-color: rgba(238, 238, 238);
}


.shell-sidebar-nav-icon {
    color: var(--color-primary);
    margin-right: 10px;
}
.shell-sidebar-nav-name {
    display: flex;

    opacity: 1;
    transition: opacity .2s;

    flex: 1 1 auto;
    align-self: center;

    #{$collapsed-selector} & {
        opacity: 0;
    }
}

.shell-sidebar-nav-root-list {
    margin: 0;
    padding: 0;
}
.shell-sidebar-nav-child-list {
    padding: 0;
    margin: 0;
    max-height: 0px;
    transition: max-height 0.2s;
}
