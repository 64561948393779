.shell-app-nav-list {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0 10px;
}

.shell-app-nav-icon {
    width: 40px;
    height: auto;
    border-radius: 50%;
    transition: transform 0.2s;
    vertical-align: middle;
    
    .shell-app-nav-entry--active & {
        width: 50px;
        box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
    }
}
.shell-app-title {
    display: none;
    color: #fff;
    font-size: 20px;
    vertical-align: middle;

    .shell-app-nav-entry--active & {
        display: initial;
    }
}

.shell-app-nav-entry {
    display: flex;
    align-items: center;

    &:not(:first-child):before {
        content: "";
        display: block;
        width: 13px;
        height: 0;
        border-bottom: 1px solid #fff;
    }
}

.shell-app-nav-link {
    &:hover,
    &:focus {
        .shell-app-nav-icon {
            transform: scale(1.2);
        }
    }
}

.shell-user {
    position: relative;

    button {
        display: flex;
        align-items: center;
        background: none;
        border: none;
        cursor: pointer;
        font: 300 1rem Roboto, "Helvetica Neue", sans-serif;

        &.button-user-name {
            color: white;
        }

        &.button-logout {
            color: #00bcd4;
            font-weight: 500;

            .icon {
                margin-right: 7px;
            }
        }
    }

    .user-details {
        position: absolute;
        right: 7px;
        top: 100%;
        z-index: -1;
        opacity: 0;
        transform: translateY(-10px);
        visibility: hidden;
        transition: all 0.3s;
        transition-property: opacity, transform;
        box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%),
            0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
        border-radius: 4px;
        background: white;
        padding: 11px 16px;
        margin-top: 5px;

        &.opened {
            opacity: 1;
            z-index: 1000; // If lower, it stays behind gooddata dashboards.
            visibility: visible;
            transform: translateY(0);
        }
    }
}
