// Default theming values
.shell {
    // A harsh default primary color makes it very obvious when we forgot to set this correctly.
    --color-primary: fuchsia;
    --color-primaryRGB: 255 0 255;
    --color-top-bar: var(--color-primary);
}

.shell--tyto {
    --color-primary: #34829a;
    --color-primaryRGB: 52 130 154;
}

.shell--heimdall {
    --color-primary: #ef5350;
    --color-primaryRGB: 239 83 80;
    --color-with-opacity: #ffcfce;
}

.shell--eden {
    --color-primary: #013a4b;
    --color-primaryRGB: 1 58 75;
}

.shell--thuja {
    --color-primary: #00796b;
    --color-primaryRGB: 0 122 108;
}
